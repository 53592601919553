jQuery(function($){

  //===============================================
  /* CMS向けにブロック要素に背景画像を設定 */
  //===============================================
  var d = document.getElementsByClassName("js-background");
  for(var i=0; i<d.length; i++){
    var f=d[i].getAttribute("data-src");
    d[i].style.backgroundImage="url('"+f+"')";
  };

  //===============================================
  /* pagetopボタン */
  //===============================================
  $('#btn_pagetop').click(function() {
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    var speed = 500; // スクロールの速度（ミリ秒）
    $('body,html').animate({scrollTop:position}, speed, 'swing'); // スクロール方式
    return false;
 });

  //===============================================
  /* FAQタブ切り替え */
  //===============================================
  $('.js-tab').on('click', function () { 
    chg_tab($(this).data('tgt'));
  });

 //===============================================
/* トップに戻るボタンなどの制御 */
//===============================================
var topBtn = $(".js-btn_pagetop");

  //スクロールしてページトップから100に達したらボタンを表示
  $(window).on('load scroll', function(){
    if($(this).scrollTop() > 100) {
      topBtn.addClass('active');
    }else{
      topBtn.removeClass('active');
    }
  });

  //フッターの手前でボタンを止める
  $(window).on('load scroll', function(){
    var height = $(document).height(), //ドキュメントの高さ 
        position = window.innerHeight + $(window).scrollTop(), //ページトップから現在地までの高さ
        footer = $('#footer').height(); //フッターの高さ
    if ( height - position  < footer ){ 
      topBtn.addClass('absolute');
    } else { 
      topBtn.removeClass('absolute');
    }
  });

  //===============================================
  /* FAQ展開 */
  //===============================================
  $('.faq_item dt').on('click',function(){
    if($(this).hasClass('active')) {
      $(this).removeClass('active');
      $(this).next('dd').removeClass('show');
    }else{
      $(this).addClass('active');
      $(this).next('dd').addClass('show');
    }
  });

  //===============================================
  /* ドロワーナビ展開 */
  //===============================================
  $('.js_btn_drawer').on('click',function(){
    if($(this).hasClass('active')) {
      $('#drawer').fadeOut();
      $('body').removeClass('fixed');
      $(this).removeClass('active');
      $('#overlay').removeClass('active');
      document.removeEventListener(
        'touchmove',
        {
          passive: false
        }
      );
    }else{
      $('#drawer').fadeIn();
      $('body').addClass('fixed');
      $(this).addClass('active');
      $(this).removeClass('behind');
      $('.js_btn_search_area').addClass('behind');
      $('#overlay').addClass('active');
      document.addEventListener(
        'touchmove',
        {
          passive: false
        }
      );
    }
  });

  $('a:not(.js_btn_drawer)').click(function(event) {
    $('body').removeClass('fixed');
    $('.js_btn_drawer').removeClass('active');
    $('.js_btn_search_area').removeClass('active');
    $('#overlay').removeClass('active');
    $('#drawer').fadeOut();
    $('#ggl_search').fadeOut();
    document.removeEventListener(
      'touchmove',
      {
        passive: false
      }
    );
  });

  //オーバーレイのクリック時の処理（ドロワーナビを閉じる）
  $('#overlay').on('click touchstart',function(){
    $('body').removeClass('fixed');
    $('.js_btn_drawer,.js_btn_drawer_sub,.js_btn_search_area').removeClass('active');
    $(this).removeClass('active');
    $('#drawer,#drawer_sub,#ggl_search').fadeOut();
    document.removeEventListener(
      'touchmove',
      {
        passive: false
      }
    );
  });

});

//===============================================
/* スクロールの高さ取得 */
//===============================================
function getScrollHeight(tgt, height) {
  var minusheight = window.innerHeight - height;
  $(tgt).css('height', minusheight);
}

//===============================================
/* スクロール変更 */
//===============================================
function psUpdate(obj) {
    getScrollHeight('#content', minusHeight2);
    obj.update();
}

function is_smartphone(){
  return ($(window).width() < 768);
}

//===============================================
/* FAQタブ切り替え処理 */
//===============================================
function chg_tab(tgt) {
  $('.js-tab').removeClass('active');
  $('.tab').hide();
  $('#tb'+tgt).addClass('active');
  $('#t'+tgt).fadeIn(200);
}